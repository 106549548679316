import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
    useGoogleLogin
} from 'react-google-login'

import isBrowser from '../validators/isBrowser'

const GoogleAuthButton = (props) => {
    const [ notComp, setNotComp ] = useState(false)
    const {
        signIn
    } = useGoogleLogin({
        clientId: process.env.GATSBY_GOOGLE_0AUTH_CLIENT,
        accessType: 'offline',
        uxMode: 'redirect',
        redirectUri: `${process.env.GATSBY_SITE_URL}/auth`
    })

    const className = classNames(
        "w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black cursor-pointer",
        props.className ? props.className : ""
    )

    const handleRedirect = (e) => {
        e.preventDefault()
        window.location.href=`https://auth-proxy-lovat.vercel.app/o/oauth2/auth/oauthchooseaccount?redirect_uri=https%3A%2F%2Fapp.ativoai.com%2Fauth&response_type=permission%20id_token&scope=email%20profile%20openid&openid.realm&client_id=983260578054-po7l6r4th6itrmbl1dqbf4s0eotqmc2g.apps.googleusercontent.com&ss_domain=https%3A%2F%2Fapp.ativoai.com&prompt&fetch_basic_profile=true&gsiwebsdk=2&flowName=GeneralOAuthFlow`
    }

    useEffect(() => {
        if ( !isBrowser() ) return

        if ( (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/FBAV/i)) ) {
            setNotComp(true)
        }
    },[])

    return (
        <div className={className} onClick={notComp ? handleRedirect : signIn}>
            {notComp && 'not compatible'}
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" className="w-5 h-5 mr-2">
                <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
            </svg>
            {props.placeholder}
        </div>
    )
}

export default GoogleAuthButton